import { envDef, firebaseConfig } from 'src/environments/env.default';

export const environment = {
  ...envDef,
  qa: true,
  prefix: 'gerencie-qa',
  version: '14.0.3',
  urlAPI: 'https://gerencie.qa.api.gestor.ladder.dev.br',
  urlBase: 'qa.gerencie.app',
  CrossDomainStorageOrigin: 'https://gerencie.qa.api.gestor.ladder.dev.br',
  firebase: firebaseConfig,
};
